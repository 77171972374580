import Typography from '@mui/material/Typography'
import { formatDate } from '../../../utils/dates'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined'
import { Box } from '@mui/material'

const Rating = ({ rating }) => {
  // if rating is 0, return only 1 empty star
  // otherwise return the number of filled stars based on the rating
  return (
    <>
      {rating === undefined || rating === 0 ? (
        <StarOutlineOutlinedIcon fontSize={'10'} sx={{ color: 'lightgrey' }} />
      ) : (
        Array.from({ length: rating }, (_, i) => (
          <StarOutlinedIcon key={`star-${i}`} fontSize={'10'} sx={{ color: 'orange' }} />
        ))
      )}
    </>
  )
}

const CampaignProspectListItem = ({ prospect, handleNavigate }) => {
  return (
    <li key={`${prospect['Prospect ID']}-${prospect.campaign?.id}`}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography
          component="span"
          onClick={() => handleNavigate(prospect.campaign?.id, prospect['Prospect ID'])}
          sx={{
            display: 'inline',
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
          }}
        >
          {formatDate(prospect?.dueDate) || 'Immediately'} - <b>{prospect.Name}</b> - {prospect.campaign.name} -{' '}
          {prospect.campaign?.Disposition?.trim() ?? 'New'} -
        </Typography>
        <Rating rating={prospect.campaign?.Rating} />
      </Box>
    </li>
  )
}

export default CampaignProspectListItem
